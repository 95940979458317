import { UploadOutlined } from "@ant-design/icons";
import { Button, Tooltip, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RealmContext } from "../../context/realmProvider";
import { updateAssessments } from "../../redux/assessmentsSlice";
import { ObjectId } from "../../utils";

const getFolder = (type) => {
    const data = [
        {
            value: "1v7L9xV_Kl6RBhIOVZpliZjTHWPXaBATg",
            label: "DISC",
        },
        {
            value: "1Co5FVzjYoMxykVOfpPqRqppSU9EBhAGb",
            label: "DISC 360",
        },
        {
            value: "1mTyoCFVPoCI-f7cUjsdvwxbxNsghjbqy",
            label: "EIQ 2",
        },
        {
            value: "1DbCUIKPTJGtpgdnx34f5uYgb3UBbue0G",
            label: "Kids DISC",
        },
        {
            value: "1P0OcIBwlaj78C5OsZeuYAIn80DcPMw5e",
            label: "Leadership Effectiveness 360",
        },
        {
            value: "1XEFo_HtBYD-4G4pEWwxDljfX7Rk4FeVG",
            label: "Learning Styles",
        },
        {
            value: "1yk-tQYYbTXnYXyvLonUMkw17fkHmplVU",
            label: "Motivators",
        },
        {
            value: "1kdRIW8pjTU66zVcyEyGkvWBt51S9IFSu",
            label: "Sale IQ Plus",
        },
    ];
    const folder = data.find((t) => t.label == type);
    return folder || null;
};
function ReFill({ entity }) {
    const [downloading, setDownloading] = useState(entity.downloading);
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, description) => {
        api[type]({
            message: "Thông báo",
            description,
            placement: "topRight",
        });
    };

    const { mongo } = useContext(RealmContext);
    const dispatch = useDispatch();
    const handleOk = async () => {
        setDownloading(true);
        const folder = getFolder(entity.type);
        openNotificationWithIcon(
            "success",
            <>
                Link đang được upload lên GDrive Folder:{" "}
                <a
                    target="_blank"
                    href={`https://drive.google.com/drive/folders/${folder.value}`}
                >
                    {folder.label}
                </a>
            </>
        );

        const fileUrl = encodeURIComponent(
            entity.type == "Motivators"
                ? "https://report.a247.co/mov/stream?url=" +
                      entity?.report?.download
                : entity?.report?.download
        );
        const id = encodeURIComponent(entity._id);
        const folderId = encodeURIComponent(folder.value);
        const fileName = encodeURIComponent(
            "[" +
                entity.type +
                "] " +
                (entity.firstname + " " + entity.lastname).replace("  ", " ") +
                " (" +
                entity.language +
                ")." +
                entity.short +
                ".pdf"
        );
        const callback = encodeURIComponent(
            "https://ap-southeast-1.aws.data.mongodb-api.com/app/wowed-ijfty/endpoint/updateDownload"
        );
        // console.log(folderId, entity?.report?.download, id, fileName);
        // console.log(
        //     `https://apis.ns24.io/api/v1/handle/tranfer?folderId=${folderId}&fileUrl=${fileUrl}&fileName=${fileName}&id=${id}&callback=${callback}`
        // );
        try {
            await fetch(
                `https://apis.ns24.io/api/v1/handle/tranfer?folderId=${folderId}&fileUrl=${fileUrl}&fileName=${fileName}&id=${id}&callback=${callback}`
            );
            const ids = [];
            ids.push(ObjectId(id));
            dispatch(
                updateAssessments({
                    ids,
                    mongo,
                    update: {
                        downloading: true,
                    },
                })
            );
        } catch (err) {
            openNotificationWithIcon(
                "error",
                "Đã có lỗi vui lòng liên hệ hỗ trợ!"
            );
            setDownloading(false);
        }
        setDownloading(false);
    };
    useEffect(() => {
        setDownloading(entity.downloading || false);
    }, [entity]);

    return (
        <>
            {contextHolder}
            <Tooltip title="Upload to GDrive">
                <Button
                    danger
                    icon={<UploadOutlined />}
                    loading={downloading}
                    size="small"
                    onClick={handleOk}
                    className="mr-1"
                    // type="dashed"
                />
            </Tooltip>
        </>
    );
}

export default ReFill;
