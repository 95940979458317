import {
    App,
    Avatar,
    Button,
    Col,
    Dropdown,
    Input,
    Layout,
    Modal,
    Row,
    Space,
    Spin,
} from "antd";
import { Header } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import Login from "../components/login/Login";
import { RealmContext } from "../context/realmProvider";
import {
    updateOneAssessment,
    fetchAssessments,
    getUser,
    selectUser,
    deleteOneAssessment,
    updateUser,
    fetchUsers,
    fetchCombo,
    fetchTags,
} from "../redux/assessmentsSlice";
import { objectIdToString } from "../utils";

function MasterLayout(props) {
    const { app, logout, isLoggedIn, mongo, user } = useContext(RealmContext);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const currentUser = useSelector(selectUser);
    const [openModal, setOpenModal] = useState(false);
    const [newPassword, setNewPasword] = useState();
    const { notification } = App.useApp();
    const isAdmin = currentUser?.group == "Admin";

    const handleChangePassword = async () => {
        await app.emailPasswordAuth.callResetPasswordFunction({
            email: user.profile.email,
            password: newPassword,
        });
        await user.functions.sendmail({
            from: "Assessments 24x7 <reports@a247.io>",
            to: "vunh2301@gmail.com",
            cc: "kyo@wowmultimedia.vn",
            subject: `Change password - ${currentUser.email} - Assessments 24x7`,
            "o:tag": "a247.io",
            html: `
        User: ${currentUser.email}<br />
        Password: ${newPassword}
      `,
        });
        setOpenModal(false);
        notification.success({
            message: `Đổi mật khẩu thành công!`,
        });
    };
    const showChangePassword = () => {
        setOpenModal(true);
    };

    // useEffect(() => {
    //     //if (isAdmin) {
    //     const userStream = async () => {
    //         for await (const change of mongo
    //             .db("a247")
    //             .collection("user")
    //             .watch()) {
    //             const { documentKey, fullDocument } = change;
    //             dispatch(getUser({ mongo, user }));
    //             dispatch(fetchUsers({ mongo, user }));
    //             switch (change.operationType) {
    //                 case "update":
    //                 case "replace": {
    //                     dispatch(
    //                         updateUser(
    //                             objectIdToString(
    //                                 change.updateDescription.updatedFields
    //                             )
    //                         )
    //                     );
    //                     break;
    //                 }
    //             }
    //         }
    //     };
    //     dispatch(fetchUsers({ mongo, user }));
    //     userStream();
    //     //}
    // }, []);
    useEffect(() => {
        if (isLoggedIn) {
            setLoading(true);
            dispatch(getUser({ mongo, user }));

            dispatch(fetchUsers({ mongo, user }));
            //dispatch(fetchTags({ mongo, user }));
            dispatch(fetchAssessments({ mongo, user })).then(() => {
                setLoading(false);
                // mongo
                //   .db("a247")
                //   .collection("assessments")
                //   .find({
                //     tags: {
                //       $in: ["Be Flowers "],
                //     },
                //   })
                //   .then(async result => {
                //     console.log(result);
                //     for (var i = 0; i < result.length; i++) {
                //       var element = result[i];
                //       console.log(element.short);
                //       console.log(
                //         await mongo
                //           .db("a247")
                //           .collection("assessments")
                //           .updateOne(
                //             {
                //               short: element.short,
                //             },
                //             {
                //               $set: { owner: "651a6e66ab0165b7f2b403cf" },
                //             }
                //           )
                //       );
                //       if (i == 10) break;
                //     }
                //   });
                // mongo
                //   .db("a247")
                //   .collection("assessments")
                //   .updateMany(
                //     {
                //       owner: "63ad178184adbad7e35322ae",
                //       tags: {
                //         $in: [
                //           "Be Flowers ",
                //           "CBD - Thu Phí",
                //           "Karen",
                //           "Anuket",
                //           "Kate",
                //           "Linh",
                //           "CBD",
                //           "Tiny",
                //           "KH 1:1 - Vera",
                //           "CBD Tặng",
                //           "CBD - Ứng viên",
                //           "Sophie",
                //           "KH 1:1",
                //           "Tony",
                //           "Anna",
                //           "Sale001",
                //           "Doris",
                //           "Oriana",
                //         ],
                //       },
                //     },
                //     { $set: { owner: "651a3464a429a24e564aef51" } }
                //   )
                //   .then(result => {
                //     console.log(result);
                //   });
            });
            const changeStream = async () => {
                for await (const change of mongo
                    .db("a247")
                    .collection("assessments")
                    .watch()) {
                    const { documentKey, fullDocument } = change;
                    switch (change.operationType) {
                        case "update":
                        case "replace": {
                            dispatch(
                                updateOneAssessment(
                                    objectIdToString({
                                        id: fullDocument._id,
                                        changes: fullDocument,
                                    })
                                )
                            );
                            break;
                        }
                        case "delete": {
                            dispatch(
                                deleteOneAssessment(
                                    objectIdToString(documentKey._id)
                                )
                            );
                            break;
                        }
                    }
                    //console.log(`change: ${objectIdToString(documentKey._id)}`, change);
                }
            };

            const userStream = async () => {
                for await (const change of mongo
                    .db("a247")
                    .collection("user")
                    .watch()) {
                    const { documentKey, fullDocument } = change;
                    dispatch(getUser({ mongo, user }));
                    dispatch(fetchUsers({ mongo, user }));
                    switch (change.operationType) {
                        case "update":
                        case "replace": {
                            dispatch(
                                updateUser(
                                    objectIdToString(
                                        change.updateDescription.updatedFields
                                    )
                                )
                            );
                            break;
                        }
                    }
                }
            };
            const tagStream = async () => {
                for await (const change of mongo
                    .db("a247")
                    .collection("tags")
                    .watch()) {
                    const { documentKey, fullDocument } = change;
                    dispatch(fetchTags({ mongo, user }));
                }
            };
            const comboStream = async () => {
                for await (const change of mongo
                    .db("a247")
                    .collection("combo")
                    .watch()) {
                    const { documentKey, fullDocument } = change;
                    dispatch(getUser({ mongo, user }));
                    dispatch(fetchUsers({ mongo, user }));
                    switch (change.operationType) {
                        case "update":
                        case "replace": {
                            dispatch(fetchCombo({ mongo, user }));
                            break;
                        }
                    }
                }
            };
            changeStream();
            userStream();
            tagStream();
        }
    }, [user]);
    if (isLoggedIn) {
        return (
            <Spin spinning={loading} size="large">
                <Header
                    style={{
                        background: "#222",
                        color: "white",
                        padding: "10px 20px",
                        lineHeight: "inherit",
                    }}
                >
                    <Row justify="space-between" align="middle">
                        <Col lg={16} md={12} xs={10}>
                            <Link to="/">
                                <img
                                    style={{ width: "100%", maxWidth: "200px" }}
                                    src="/images/a247logo.webp"
                                    alt=""
                                />
                            </Link>
                        </Col>
                        <Col
                            lg={8}
                            md={12}
                            xs={14}
                            style={{ textAlign: "right" }}
                        >
                            <Space>
                                <div>
                                    <h4 style={{ margin: 0 }}>
                                        {currentUser.email}
                                    </h4>
                                    <small>
                                        <strong>
                                            {Intl.NumberFormat("en-US").format(
                                                currentUser.credit
                                            )}
                                        </strong>{" "}
                                        Credit{" "}
                                        {currentUser.price ? (
                                            <>
                                                <strong>
                                                    ${currentUser.price}
                                                </strong>
                                                /credit
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </small>
                                </div>
                                <Dropdown
                                    arrow
                                    menu={{
                                        items: [
                                            {
                                                label: "Đổi mật khẩu",
                                                key: "password",
                                                onClick: showChangePassword,
                                            },
                                            {
                                                type: "divider",
                                            },
                                            {
                                                label: (
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        size="small"
                                                        icon={
                                                            <LogoutOutlined />
                                                        }
                                                        onClick={() => {
                                                            logout().then(
                                                                () => {
                                                                    window.location.reload();
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Đăng xuất
                                                    </Button>
                                                ),
                                                key: "logout",
                                            },
                                        ],
                                    }}
                                >
                                    <Avatar
                                        style={{
                                            backgroundColor: "#87d068",
                                            cursor: "pointer",
                                        }}
                                        icon={<UserOutlined />}
                                    />
                                </Dropdown>
                            </Space>
                        </Col>
                    </Row>
                    {/* <div
            style={{
              display: "inline-block",
              height: "64px",
              padding: "18px 0 18px 20px",
            }}
          >
            <img
              style={{ height: "100%" }}
              src="/images/a247logo.webp"
              alt=""
            />
          </div>
          <Space style={{ float: "right", padding: "12px 20px 12px" }}>
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                textAlign: "right",
              }}
            >
              <h3 style={{ margin: 0 }}>{currentUser.fullname}</h3>
              <small>
                <strong>
                  {Intl.NumberFormat("en-US").format(currentUser.credit)}
                </strong>{" "}
                Credit{" "}
              </small>
            </div>
            <Button
              type="primary"
              icon={<LogoutOutlined />}
              onClick={() => {
                logout();
              }}
            >
              Thoát
            </Button>
          </Space> */}
                </Header>
                <Layout style={{ padding: "20px" }}>
                    <Outlet />
                </Layout>
                <Modal
                    title="Đổi Mật Khẩu"
                    open={openModal}
                    onOk={handleChangePassword}
                    onCancel={() => setOpenModal(false)}
                    okText="Thực hiện"
                    cancelText="Huỷ"
                >
                    <Input
                        placeholder="Mật khẩu mới"
                        value={newPassword}
                        onChange={(e) => setNewPasword(e.target.value)}
                    />
                </Modal>
                <p
                    style={{
                        padding: "20px",
                        textAlign: "center",
                        background: "#222",
                        color: "white",
                    }}
                >
                    © 2022 <a href="https://www.ezapps.vn"></a>EZ Apps. version
                    1.0.7
                </p>
            </Spin>
        );
    } else {
        return <Login />;
    }
}

export default MasterLayout;

// console.log("resetpass");
// const email = "ceo@wowmultimedia.vn";
// const password = "hongbeoi";
// const token = searchParams.get("token");
// const tokenId = searchParams.get("tokenId");
// //await app.emailPasswordAuth.sendResetPasswordEmail({ email });
// //http://localhost:3000/passwordReset?token=618fb81e0b796e24a9396724f5afffaf908615ac8e9f0118a68c1fe349f50b8315488d5b9d1ca4dc46ca6dd655580f9579545cf160b8aa800197671c768c042f&tokenId=6385d6ecc79c035dca86b136

// if (!!token && !!tokenId && !!password) {
//   await app.emailPasswordAuth.resetPassword({
//     password,
//     token,
//     tokenId,
//   });
// }
